import * as React from "react"

const CompilerExplorerIcon = (props) => (
  <svg
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <style>{".st1{fill:#3c3c3f}"}</style>
    <path
      d="M756.4 658.1c-3.2-5.7-3.2-12.7 0-18.5 3.8-6.9 7.3-13.8 10.6-21.1 2.3-5.2-1.4-11.1-7.2-11.1H697c-6.4 0-12.5 3.2-16 8.6-8.1 12.3-17.5 24.3-28.3 35-40.3 40.3-94 62.5-151 62.5S391 691.3 350.7 651s-62.5-94-62.5-151 22.2-110.7 62.5-151 94-62.5 151-62.5 110.7 22.2 151 62.5c10.7 10.7 20.2 22.7 28.3 35 3.5 5.4 9.5 8.6 16 8.6h62.8c5.7 0 9.5-5.9 7.2-11.1-3.2-7.2-6.8-14.1-10.6-21.1-3.2-5.7-3.1-12.7 0-18.5l21.3-38.6c4.1-7.5 2.8-16.8-3.2-22.8l-53.2-53.2c-6-6-15.4-7.4-22.8-3.2l-38.7 21.4c-5.8 3.2-12.7 3.1-18.5-.1-18.4-10.1-37.9-18.1-58.1-23.9-6.3-1.8-11.3-6.7-13.1-13L557.8 166c-2.4-8.2-9.9-13.9-18.4-13.9h-75.3c-8.5 0-16.1 5.6-18.4 13.9l-12.2 42.3c-1.8 6.3-6.8 11.2-13.1 13-20.2 5.9-39.7 13.9-58.1 24-5.8 3.2-12.7 3.2-18.5.1L305 224.1c-7.5-4.1-16.8-2.8-22.8 3.2L229 280.5c-6 6-7.4 15.4-3.2 22.8l21.4 38.7c3.2 5.8 3.1 12.7-.1 18.5-10.1 18.4-18.1 37.9-23.9 58.1-1.8 6.3-6.7 11.3-13 13.1l-42.3 12.2c-8.2 2.4-13.9 9.9-13.9 18.4v75.3c0 8.5 5.6 16.1 13.9 18.4l42.3 12.2c6.3 1.8 11.2 6.8 13 13.1 5.9 20.2 13.9 39.7 24 58.1 3.2 5.8 3.2 12.7.1 18.5l-21.4 38.7c-4.1 7.5-2.8 16.8 3.2 22.8l53.2 53.2c6 6 15.4 7.4 22.8 3.2l38.7-21.4c5.8-3.2 12.7-3.1 18.5.1 18.4 10.1 37.9 18.1 58.1 23.9 6.3 1.8 11.3 6.7 13.1 13l12.2 42.3c2.4 8.2 9.9 13.9 18.4 13.9h75.3c8.5 0 16.1-5.6 18.4-13.9l12.2-42.3c1.8-6.3 6.8-11.2 13.1-13 20.2-5.9 39.7-13.9 58.1-24 5.8-3.2 12.7-3.2 18.5-.1l38.7 21.4c7.5 4.1 16.8 2.8 22.8-3.2l53.2-53.2c6-6 7.4-15.4 3.2-22.8z"
      fill="#67c52a"
      style={{
        fill: "#67c52a",
      }}
      transform="translate(-165.28 -213.17) scale(1.4265)"
    />
    <path
      className="st1"
      fill="#3c3c3f"
      d="M367.803 345.447h368.608v68.472H367.803zM367.803 467.128h304.13V535.6h-304.13zM367.803 588.666h368.608v68.472H367.803z"
    />
  </svg>
)

export default CompilerExplorerIcon